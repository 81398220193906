import { useEffect, useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Tooltip } from '@mui/material';
import { parseISO } from 'date-fns';
import { RichTextInput } from 'ra-input-rich-text';
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useGetOne,
  WithRecord,
} from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { DataProviderResource, QueryParamConstant } from 'src/constants';
import { SortDirectionTypeDto, OfferOptionTypeDto, OfferOrderStatusAggregateDto, OfferOrderStatusDto } from 'src/dtos';
import { OfferOrderAggregate } from 'src/models';
import { formatPreviewImage } from 'src/utils';

import { sortOrderChoices } from '../../../../constants/choices/sort.order.choices';
import Styles from '../../../../constants/styles';
import { GridBreak } from '../../../../lib/GridBreak/GridBreak';
import ModalOfferOptionCreate from '../ModalOfferOption/ModalOfferOptionCreate';
import AccumulatedInvestmentInput from './AccumulatedInvestmentInput';

interface InfoCreateEditProps {
  isEditMode?: boolean;
  setCategorySelected: Function;
  record: any;
}

export const InfoCreateEdit = ({ isEditMode = false, setCategorySelected, record }: InfoCreateEditProps) => {
  const categoryId = useWatch({ name: 'categoryId' });
  const statusId = useWatch({ name: 'statusId' });
  const [offerStatusName, setOfferStatusName] = useState<string>();
  const { register, setValue } = useFormContext();

  const { data: offerOrderAggregate } = useGetOne<OfferOrderAggregate>(DataProviderResource.AggregateOfferOrders, {
    id: record.id.toString(),
  });

  const { data: category } = useGetOne('offer-options', {
    id: categoryId,
    meta: { type: OfferOptionTypeDto.CATEGORY },
  });

  const { data: offerStatus } = useGetOne('offer-options', {
    id: statusId,
    meta: { type: OfferOptionTypeDto.STATUS },
  });

  useEffect(() => {
    setValue('_statusName', offerStatusName);
  }, [offerStatusName]);

  useEffect(() => {
    if (offerStatus?.name) {
      setOfferStatusName(offerStatus?.name || '');
    }
  }, [offerStatus]);

  useEffect(() => {
    if (category?.id) setCategorySelected(category?.name);
  }, [category]);

  useEffect(() => {
    if (record) {
      const isNumber = !isNaN(record.ordersSummation);

      const selectedStatus = isNumber ? [] : record.ordersSummation?.split(',') || [];
      record._ordersSummation = Object.keys(OfferOrderStatusDto).map((key: string) => {
        const statusData = offerOrderAggregate?.statusSummary?.find(
          (item: OfferOrderStatusAggregateDto) => key === item.status,
        );
        const isSelectedStatus = selectedStatus?.includes(key) || false;
        if (statusData)
          return {
            status: key,
            totalOfferOrders: statusData.total,
            sumRaisedInvestment: statusData.totalAmount,
            _includeInSummation: isSelectedStatus,
          };

        return { status: key, totalOfferOrders: 0, sumRaisedInvestment: 0, _includeInSummation: isSelectedStatus };
      });

      if (isNumber) {
        record._ordersSummation.custom = record.ordersSummation;
        record._ordersSummation.useCustom = !record.ordersSummation || record.ordersSummation === '' ? undefined : true;
      }
    }
  }, [record, offerOrderAggregate]);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <h2>{isEditMode ? 'Edit' : 'Add'} General Information</h2>
      </Grid>
      <Grid item xs={12}>
        {isEditMode && (
          <WithRecord
            render={record => {
              record._logo_url_temp = record.logoUrl;

              return (
                <ImageInput
                  format={formatPreviewImage}
                  source='_logo_url_temp'
                  label='Logo'
                  accept='image/png,image/jpg,image/jpeg,image/bmp,image/gif,image/webp,image/psd'>
                  <ImageField source='src' />
                </ImageInput>
              );
            }}
          />
        )}
        {!isEditMode && (
          <ImageInput
            source='_logo_url_temp'
            label='Logo'
            accept='image/png,image/jpg,image/jpeg,image/bmp,image/gif,image/webp,image/psd'>
            <ImageField source='src' />
          </ImageInput>
        )}
      </Grid>
      <Grid item xs={6}>
        <TextInput label='Short URL' source='shortUrlRoute' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextInput label='Name' source='name' validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DateInput
          label='Start Date'
          source='startDate'
          validate={[required()]}
          fullWidth
          parse={(value: any) => parseISO(value)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextInput label='Close Date' source='closeDate' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextInput label='Anticipated Start Date' source='anticipatedStartDate' fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextInput label='Yield' source='yield' validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextInput label='Yield description' source='yieldDescription' validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextInput label='Sector' source='sector' validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <BooleanInput label='Featured' source='featured' validate={[required()]} fullWidth />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData &&
            formData?.featured &&
            formData.featured !== record?.featured && (
              <span {...rest} style={Styles.offer.warningMessage}>
                Will turn off Featured status in all other offers!
              </span>
            )
          }
        </FormDataConsumer>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <BooleanInput label='New' source='new' validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <BooleanInput label='Visible to end users' source='visibleToEndUsers' validate={[required()]} fullWidth />
      </Grid>
      <GridBreak />
      <GridBreak />
      <Grid item xs={12} sm={6} md={4}>
        <SelectInput
          label='Sort Order'
          source='sortOrder'
          choices={sortOrderChoices}
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput source='statusId' reference='offer-options' filter={{ type: 'offerStatus' }}>
          <SelectInput
            label='Offer Status'
            optionText='name'
            validate={[required()]}
            fullWidth
            create={<ModalOfferOptionCreate type='offerStatus' />}
          />
        </ReferenceInput>
        {isEditMode && <input hidden {...register('_statusName')} />}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput source='offerTypeId' reference='offer-options' filter={{ type: 'offerType' }}>
          <SelectInput
            label='Offer Type'
            optionText='name'
            validate={[required()]}
            fullWidth
            create={<ModalOfferOptionCreate type='offerType' />}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput source='industryId' reference='offer-options' filter={{ type: 'offerIndustry' }}>
          <SelectInput
            label='Industry'
            optionText='name'
            validate={[required()]}
            fullWidth
            create={<ModalOfferOptionCreate type='offerIndustry' />}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput source='categoryId' reference='offer-options' filter={{ type: 'offerCategory' }}>
          <SelectInput
            label='Category'
            optionText='name'
            validate={[required()]}
            fullWidth
            create={<ModalOfferOptionCreate type='offerCategory' />}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput source='securityTypeId' reference='offer-options' filter={{ type: 'offerSecurityType' }}>
          <SelectInput
            label='Security type'
            optionText='name'
            validate={[required()]}
            fullWidth
            create={<ModalOfferOptionCreate type='offerSecurityType' />}
          />
        </ReferenceInput>
      </Grid>
      {isEditMode && (
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput
            source='subscriptionAgreementId'
            reference='offer-documents'
            filter={{ type: 'subscription_agreement', offerId: record?.id ?? null }}>
            <SelectInput label='Subscription Agreement' optionText='url' fullWidth />
          </ReferenceInput>
        </Grid>
      )}
      <GridBreak />
      <Grid item xs={12} sm={6} md={4}>
        <ReferenceInput
          source='privateQuoteId'
          reference='private-quotes'
          sort={{ field: 'symbol', order: SortDirectionTypeDto.Desc }}
          allowEmpty
          perPage={QueryParamConstant.SKIP_PAGINATION}>
          <SelectInput label='Private Quote' optionText='symbol' fullWidth />
        </ReferenceInput>
      </Grid>
      {offerStatusName === 'Closed' && Boolean(record?._ordersSummation) && (
        <AccumulatedInvestmentInput record={record} />
      )}
      <Grid item xs={12}>
        <Tooltip title='An offer is considered recurring if it has two ore more close dates.' placement='top-start'>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <InfoOutlinedIcon /> <span>Recurring offer</span>
          </span>
        </Tooltip>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <ArrayInput {...rest} source='closeDates' label='Close Date(s)' disabled={!formData}>
                <SimpleFormIterator>
                  <DateInput
                    label={false}
                    source=''
                    fullWidth
                    helperText={false}
                    validate={required()}
                    parse={(value: any) => parseISO(value)}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source='nonVisibleAppFields' label='Non visible app fields'>
          <SimpleFormIterator>
            <SelectInput
              label={false}
              choices={[
                { id: 'yield', label: 'Yield' },
                { id: 'yieldDescription', label: 'Yield Description' },
              ]}
              fullWidth
              helperText={false}
              validate={required()}
              optionText='label'
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item xs={12}>
        <ArrayInput source='investmentRanges' label='Investment Ranges'>
          <SimpleFormIterator>
            <TextInput label={false} source='' fullWidth helperText={false} validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <RichTextInput label='Exit Description' source='exitDescription' validate={[required()]} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RichTextInput label='Subtext On Closed Offer' source='subtextOnClosed' fullWidth />
      </Grid>
    </Grid>
  );
};
export default InfoCreateEdit;
