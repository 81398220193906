import * as React from 'react';

import { Grid } from '@mui/material';
import { NumberField, SimpleShowLayout, useGetOne } from 'react-admin';

import { AccountBalanceDetails } from '../../../models/accountBalances.models';
import { AccountNontradablePositionList } from '../../Orders/AccountNontradablePositionList';
import { AccountTradablePositionsList } from '../../Orders/AccountTradablePositionsList';
import { UserAchRelationsList } from './UserAchRelationsList';

export interface TransactionsShowProps {
  accountId: number;
}

export const SummaryShow = ({ accountId }: TransactionsShowProps) => {
  const { data: accountBalance } = useGetOne<AccountBalanceDetails>('balances', { id: accountId.toString() });

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Account Summary</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Cash balance'
            source='cashBalance'
            record={accountBalance}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Cash available'
            source='cashAvailable'
            record={accountBalance}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Funds available for withdrawal'
            source='cashAvailableForWithdrawal'
            record={accountBalance}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12}>
        <SimpleShowLayout>
          <h2>ACH Relationships</h2>
          <UserAchRelationsList accountId={accountId} />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12}>
        <SimpleShowLayout>
          <h2> Securities Holdings</h2>
          <AccountTradablePositionsList accountId={accountId} />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12}>
        <SimpleShowLayout>
          <h2> Non-Traded Holdings</h2>
          <AccountNontradablePositionList accountId={accountId} />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
