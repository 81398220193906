import _ from 'lodash';
import { Admin, defaultTheme, Resource } from 'react-admin';
import './App.css';

import { AccountShow } from './components/Accounts';
import Login from './components/Login';
import OfferOrders from './components/OfferOrders';
import {
  PreExistingUser,
  ACHRelationships,
  Offers,
  OfferOptions,
  OfferDocuments,
  IndicationOfInterests,
  ACHTransfers,
  Config,
  PrivateQuotes,
} from './components/resources';
import { UserIcon, UserList, UserShow } from './components/Users';
import { DataProviderResource } from './constants';
import Colors from './constants/colors';
import AuthProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

import './assets/style.css';

const theme = _.merge({}, defaultTheme, {
  components: {
    RaList: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-headerCell': {
            color: Colors.GREY,
            fontSize: '0.75rem',
          },
          '& .RaDatagrid-row': {
            height: '48px',
          },
          '& .MuiCardContent-root': {
            overflow: 'auto',
            minHeight: '600px',
          },
        },
      },
    },
    RaShow: {
      styleOverrides: {
        root: {
          '& .RaLabeled-label': {
            fontWeight: 'bold',
            fontSize: '0.85rem !important',
          },
          '& .MuiTypography-body2, li': {
            fontSize: '0.75rem',
          },
          '& .MuiCardContent-root': {
            overflow: 'auto',
            minHeight: '600px',
          },
        },
      },
    },
    RaEdit: {
      styleOverrides: {
        root: {
          '& .MuiCardContent-root': {
            overflow: 'auto',
            minHeight: '600px',
          },
        },
      },
    },
    RaCreate: {
      styleOverrides: {
        root: {
          '& .MuiCardContent-root': {
            overflow: 'auto',
            minHeight: '600px',
          },
        },
      },
    },
  },
});

const App = () => (
  <Admin
    title='My IPO Admin Panel'
    loginPage={Login}
    dataProvider={dataProvider}
    authProvider={AuthProvider}
    theme={theme}>
    <Resource name='configs' options={{ label: 'Configs' }} {...Config} />
    <Resource name='users' list={UserList} show={UserShow} icon={UserIcon} options={{ label: 'Users & Accounts' }} />
    <Resource name={DataProviderResource.Accounts} show={AccountShow} />
    <Resource
      name='preexistingusers'
      list={PreExistingUser.List}
      icon={PreExistingUser.Icon}
      options={{ label: 'Legacy Users' }}
    />
    <Resource name='offers' options={{ label: 'Offers' }} {...Offers} />
    <Resource name='offer-options' options={{ label: 'Offer Options' }} {...OfferOptions} />
    <Resource name='offer-documents' options={{ label: 'Offer Documents' }} {...OfferDocuments} />
    <Resource name={DataProviderResource.OfferOrders} options={{ label: 'Offer Orders' }} {...OfferOrders} />
    <Resource
      name='achrelationships'
      list={ACHRelationships.List}
      show={ACHRelationships.Show}
      icon={ACHRelationships.Icon}
      options={{ label: 'ACH Relationships' }}
    />

    <Resource
      name='achtransfers'
      list={ACHTransfers.List}
      show={ACHTransfers.Show}
      icon={ACHTransfers.Icon}
      options={{ label: 'Money Transfers' }}
    />
    <Resource name='indication-interests' options={{ label: 'Indication of Interests' }} {...IndicationOfInterests} />

    <Resource options={{ label: 'Private Quotes' }} {...PrivateQuotes} />
  </Admin>
);

export default App;
