import { UserDto } from 'src/dtos';
import { mapAccountDtoToModel } from 'src/mappers';
import { User } from 'src/models';

export const mapUserDtoToModel = (item: UserDto): User => ({
  id: item.id,
  firstName: item.firstName,
  middleName: item.middleName,
  lastName: item.lastName,
  suffix: item.suffix,
  email: item.email,
  phoneNumber: item.phoneNumber,
  isLocked: item.isLocked,
  isClosed: item.isClosed,
  isApproved: item.isApproved,
  isMfaEnabled: item.isMfaEnabled,
  signUpStatus: item.signUpStatus,
  accounts: item.accounts.map(mapAccountDtoToModel),
  postCloseOffers: item.postCloseOffers,
  createdAt: item.createdAt,
});
